import Page from '../../components/Page'
import PageLayout from '../../components/PageLayout'

const NotFound: React.FC = () => (
  <Page title="Log out">
    <PageLayout title="404" subtitle="This page could not be found." />
  </Page>
)

export default NotFound
